<template>
  <Layout></Layout>
</template>
<script>
import Layout from '@/layout'
export default {
  components: { Layout }
}
</script>
<style lang="scss">
@import url(http://fonts.googleapis.com/css?family=Rock+Salt);
@import url(http://fonts.googleapis.com/css?family=Dancing+Script);
/* ---------------------------------------------------------------------------- */
/* ------------------------------------Html-Body------------------------------- */
/* ---------------------------------------------------------------------------- */

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  color: #1c424d;
  font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
html {
  -webkit-text-size-adjust: none;
}
.video embed,
.video object,
.video iframe {
  width: 100%;
  height: auto;
}
img {
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
}

a {
  color: #79b6e4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #f1c40f;
}

h1 {
  font-weight: 700;
}
h1 strong {
  font-weight: 900;
}
h2 {
  font-size: 36px;
  margin: 0;
}
h3 {
  font-size: 16px;
  font-weight: 700;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: Montserrat, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

ul,
ol {
  list-style: none;
}

input:not([type]),
input[type='color'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='url'],
input[type='text'],
input[type='search'],
textarea,
.form-control,
select {
  border-radius: 0 0 0 0;
  box-shadow: none;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: normal;
  height: 40px;
  line-height: 1.2;
  margin: 10px 0;
  outline: medium none;
  padding: 7px 10px 4px;
  transition: border-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
  width: 100%;
  background-color: transparent;
  border: 2px solid #444444;
}

.img-centered {
  margin: 0 auto;
}
.tlinks {
  text-indent: -9999px;
  height: 0;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
}
.clear {
  content: '\0020';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix:after,
.clearfix:before {
  clear: both;
  content: '\0020';
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

/* --Line-- */
hr {
  height: 3px;
  width: 60px;
  position: relative;
  background: #626a29;
  border: 0;
  margin-bottom: 30px;
}
</style>
