<!--
 * @Descripttion:
 * @version:
 * @Author: silva.yanhui
 * @Date: 2020-10-26 15:11:45
 * @LastEditors: silva.yanhui
 * @LastEditTime: 2020-10-26 15:38:09
-->
<template>
  <transition name="fade">
    <div class="loading" v-if="loading" :style='{backgroundColor:backgroundColor}'>
      <svg-icon name="loading" :style="{ width }"></svg-icon> {{ loadingText }}
    </div>
  </transition>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: ['loading', 'loadingText', 'width', 'backgroundColor'],
  components: {}
})
export default class extends Vue {
  setup() {
    const state = reactive({})
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
  svg {
    transition: 0.5s;
    width: 7rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  /* .fade-leave-active below version 2.1.8 */
  opacity: 0;
  svg {
    transform: scale(0);
  }
}
</style>
