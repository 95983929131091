<!--
 * @Descripttion: 
 * @version: 
 * @Author: silva.yanhui
 * @Date: 2020-10-13 11:20:15
 * @LastEditors: silva.yanhui
 * @LastEditTime: 2020-10-24 10:01:49
-->
<template>
  <div>
    <Header></Header>
    <div class="content">
      <router-view />
    </div>
    <Footer className="footer"></Footer>
    <ScrollToTop></ScrollToTop>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { Options, Vue } from 'vue-class-component'
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollToTop from '@/components/BackToTop'
@Options({
  components: {
    Header,
    Footer,
    ScrollToTop
  }
})
export default class Home extends Vue {
  setup() {
    const state = reactive({
      count: 0
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" type="text/css">
.content {
  position: relative;
  background: $basseBackColor;
  min-height: calc(100vh - 65px);
  overflow: hidden;
}
</style>
