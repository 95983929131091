<!--
 * @Descripttion:
 * @version:
 * @Author: silva.yanhui
 * @Date: 2020-10-13 14:08:51
 * @LastEditors: Silva.yanhui
 * @LastEditTime: 2021-02-26 10:27:03
-->
<template>
	<!-- /////////////////////////////////////////Footer -->
	<footer>
		<div id="footer">
			<div class="container">
				<p>
					Copyright &copy;2020. Star Game All rights reserved.
					<a href="https://beian.miit.gov.cn" target="_blank"
						>粤ICP备2024312250号</a
					>
				</p>
				<p>
					<router-link to="/privacy-policy" style="text-transform: uppercase;"
						>privacy-policy</router-link
					>
				</p>
			</div>
		</div>
	</footer>
	<!-- Footer -->
</template>
<script>
import { reactive, toRefs } from 'vue'
import { Options, Vue } from 'vue-class-component'

@Options({
	components: {}
})
export default class extends Vue {
	setup() {
		const state = reactive({})
		return {
			...toRefs(state)
		}
	}
}
</script>

<style lang="scss" scoped type="text/css">
/* ---------------------------------------------------------------------------- */
/* -------------------------------------Footer--------------------------------- */
/* ---------------------------------------------------------------------------- */
#footer {
	background: $basseBackColor;
	padding: 15px 0 10px 0;
	color: #42626b;
	text-align: center;
}
#footer p {
	margin-top: 10px;
	a {
		color: #42626b;
		&:hover {
			color: red;
		}
	}
}
</style>
