<template>
  <div
    class="back-to-top"
    @click="backToTop"
    v-show="showReturnToTop"
    @mouseenter="show"
    @mouseleave="hide"
  >
    <i
      :class="[bttOption.iClass]"
      :style="{ color: bttOption.iColor, 'font-size': bttOption.iFontsize }"
    >
      <svg
        t="1602818610442"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="622"
      >
        <path
          d="M512 78.694382C273.362632 78.694382 79.849863 272.20715 79.849863 510.844518s193.512769 432.150137 432.150137 432.150137c238.637368 0 432.150137-193.512769 432.150137-432.150137S750.758998 78.694382 512 78.694382zM792.234707 669.084689c-19.460744 19.460744-50.962822 19.339114-70.423566-0.12163L512 458.300511 302.310488 668.96306c-19.339114 19.460744-50.841193 19.582373-70.423566 0.12163-19.460744-19.339114-19.582373-50.962822-0.12163-70.423566l244.96211-246.056776c9.365483-9.365483 22.014966-14.595558 35.272598-14.595558 13.257632 0 25.907115 5.230075 35.272598 14.595558l245.083739 246.056776C811.695451 618.243497 811.695451 649.745575 792.234707 669.084689z"
          p-id="623"
          fill="#222a34"
        ></path>
      </svg>
    </i>

    <span
      class="tips"
      :class="[bttOption.iPos]"
      :style="{ color: bttOption.textColor }"
      v-show="showTooltips"
      >{{ bttOption.text }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'back-to-top',
  props: {
    text: {
      // 文本提示
      type: String,
      default: 'Back'
    },
    textColor: {
      // 文本颜色
      type: String,
      default: '#fff'
    },
    iPos: {
      // 文本位置
      type: String,
      default: 'right'
    },
    iClass: {
      // 图标形状
      type: String,
      default: 'fzicon fz-ad-fanhuidingbu1'
    },
    iColor: {
      // 图标颜色
      type: String,
      default: '#fff'
    },
    iFontsize: {
      // 图标大小
      type: String,
      default: '32px'
    },
    pageY: {
      // 默认在哪个视图显示返回按钮
      type: Number,
      default: 400
    },
    transitionName: {
      // 过渡动画名称
      type: String,
      default: 'linear'
    }
  },
  data: function() {
    return {
      showTooltips: false,
      showReturnToTop: false
    }
  },
  computed: {
    bttOption() {
      return {
        text: this.text,
        textColor: this.textColor,
        iPos: this.iPos,
        iClass: this.iClass,
        iColor: this.iColor,
        iFontsize: this.iFontsize
      }
    }
  },
  methods: {
    show() {
      // 显示隐藏提示文字
      return (this.showTooltips = true)
    },
    hide() {
      return (this.showTooltips = false)
    },
    currentPageYOffset() {
      // 判断滚动区域大于多少的时候显示返回顶部的按钮
      window.pageYOffset > this.pageY
        ? (this.showReturnToTop = true)
        : (this.showReturnToTop = false)
    },
    scrollToTop() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, scrollTop - scrollTop / 8)
      }
    },
    backToTop() {
      window.requestAnimationFrame(this.scrollToTop)
    }
  },
  created() {
    window.addEventListener('scroll', this.currentPageYOffset)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.currentPageYOffset)
  }
}
</script>

<style scoped lang="scss">
.back-to-top {
  position: fixed;
  bottom: 5%;
  right: 50px;

  z-index: 9999;
  cursor: pointer;
  width: auto;
  i svg {
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 2px;
    width: 40px;
    background-color: rgba(234, 231, 231, 0.52);
    border-radius: 50%;
    transition: all 0.3s linear;
    &:hover {
      border-radius: 10%;
      color: #fff !important;
      animation: backToTop 1s infinite linear;
    }
  }
  .tips {
    display: inline-block;
    position: absolute;
    word-break: normal;
    white-space: nowrap;
    width: auto;
    font-size: 12px;
    color: #fff;
    z-index: -1;
  }
  .left {
    right: 0;
    top: 50%;
    margin-right: 50px;
    transform: translateY(-50%);
  }
  .right {
    left: 0;
    top: 50%;
    margin-left: 50px;
    transform: translateY(-50%);
  }
  .bottom {
    bottom: 0;
    margin-top: 50px;
  }
  .top {
    top: 0;
    margin-bottom: 50px;
  }
}

@keyframes backToTop {
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
</style>
